import React, { useState } from "react";
import { Link } from "react-router-dom";
import MicroLoader from "./MicroLoader";
import UserBridge from "../bridges/UserBridge";
const BookingHeader = () => {
  const [signOutLoader, setSignOutLoader] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const signOut = async () => {
    setSignOutLoader(true);
    const response = await UserBridge.logout();
    const result = await response.json();
    if (result.error) {
      setSignOutLoader(false);
      alert(`There was an error, please try again.`);
    } else {
      setSignOutLoader(false);
      window.location.href = "https://ffnaw.com";
    }
  };

  const toggleMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  return (
    <>
      <nav className="bookingHeader">
        <div className="desktop">
          <div className="left">
            <a href="https://ffnaw.com">
              <img src="../../assets/watermark-alpha.png" />
            </a>
          </div>
          <div className="right">
            <Link to="https://ffnaw.com">Home</Link>
            <Link to="/login/?to=clients">
              <button>Client Portal</button>
            </Link>
            <Link to="/login">
              <button>Provider Portal</button>
            </Link>
          </div>
        </div>
        <div className="mobile">
          <div className="left">
            <a href="https://ffnaw.com">
              <img src="../../assets/watermark-alpha.png" />
            </a>
          </div>
          <div className="right">
            <button onClick={toggleMenu}>Menu</button>
          </div>
        </div>
      </nav>
      <div
        id="menu"
        className={
          menuOpen ? `mobileSlideOut menuOpen` : `mobileSlideOut menuClosed`
        }
        onClick={toggleMenu}
      >
        <label>Menu</label>
        <Link to="/providers">Provider Portal</Link>
      </div>
    </>
  );
};

export default BookingHeader;
