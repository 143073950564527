import React from "react";
import { Outlet } from "react-router";
import BookingFooter from "../../components/BookingFooter";
import ClientHeader from "../../components/ClientHeader";

const ClientView = () => {
  return (
    <section className="providerView">
      <ClientHeader />
      <div className="content">
        <Outlet />
      </div>
      <BookingFooter />
    </section>
  );
};

export default ClientView;
