import React from "react";

const MicroLoader = () => {
  return (
    <div className="microLoaderContainer">
      <div className="loader" />
    </div>
  );
};

export default MicroLoader;
