import React from "react";

const LoginReset = () => {
  return (
    <section className="loginReset">
     <h1>LoginReset</h1>
    </section>
  );
};

export default LoginReset;
