import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import UserBridge from "../../bridges/UserBridge";
import MicroLoader from "../../components/MicroLoader";

const LoginSignup = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const navi = useNavigate();

  const trySignup = async () => {
    setButtonLoader(true);

    let emailsMatch =
      document.getElementById("email").value ===
        document.getElementById("email2").value &&
      document.getElementById("email").value !== "";
    let passwordsMatch =
      document.getElementById("password").value ===
        document.getElementById("password2").value &&
      document.getElementById("password").value !== "";
    let fnamePresent = document.getElementById("fname").value !== "";
    let lnamePresent = document.getElementById("lname").value !== "";
    console.log(`
      emailCheck: ${emailsMatch}
      passwordCheck: ${passwordsMatch}
      fnameCheck: ${fnamePresent}
      lnameCheck: ${lnamePresent}`);

    if (!emailsMatch) {
      alert("Emails do not match. Please try again");
    } else if (!passwordsMatch) {
      alert("Passwords do not match. Please try again");
    } else if (!fnamePresent) {
      alert("Please check your first name");
    } else if (!lnamePresent) {
      alert("Please check your last name");
    } else {
      const response = await UserBridge.signUp(
        document.getElementById("fname").value,
        document.getElementById("lname").value,
        document.getElementById("email").value,
        document.getElementById("password").value,
      );
      const result = await response.json();
      if (result.error === true) {
        alert(result.msg);
      } else {
          const email = document.getElementById(`email`).value;
          const password = document.getElementById(`password`).value;
          const response = await UserBridge.login(email, password);
          const result = await response.json();
          if (result.error) {
            navi("/login");
            alert(result.msg);
            setButtonLoader(false);
          } else {
            const params = new URLSearchParams(window.location.search);
            const checkout = params.get("checkout");
            console.log(params);
            if (checkout) {
              navi("/store/checkout?login=true");
            } else {
              navi("/v1/account");
            }
          }
        alert(
          "Thank you for signing up to Flex Factory Nutrition. You will now be redirected to your checkout session or your account dashboard."
        );
        navi("/v1/account");
      }
    }

    setButtonLoader(false);
  };

  return (
    <section className="loginSignup">
      <div className="inputs">
        <h3>Sign Up</h3>
        <input
          name="fname"
          id="fname"
          type="text"
          placeholder="Please enter your first name"
        />
        <input
          name="lname"
          id="lname"
          type="text"
          placeholder="Please enter your last name"
        />
        <input
          name="email"
          id="email"
          type="email"
          placeholder="Please enter your email"
          autoComplete="email"
        />
        <input
          name="email2"
          id="email2"
          type="email"
          placeholder="Please confirm your email"
          autoComplete="email"
        />
        <input
          name="password"
          id="password"
          type="password"
          placeholder="Please enter your password"
          autoComplete="password"
        />
        <input
          name="password2"
          id="password2"
          type="password"
          placeholder="Please confirm your password"
          autoComplete="password"
        />
        <button
          id="loginButton"
          onClick={async () => {
            trySignup();
          }}
        >
          {buttonLoader ? <MicroLoader /> : "Sign Up"}
        </button>
      </div>
    </section>
  );
};

export default LoginSignup;
