import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import UserBridge from "../../bridges/UserBridge";
import { Link } from "react-router-dom";
import MicroLoader from "../../components/MicroLoader";

const LoginDefault = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const navi = useNavigate();
  const { checkout } = useParams();

  const tryLogin = async () => {
    setButtonLoader(true);
    const email = document.getElementById(`email`).value;
    const password = document.getElementById(`password`).value;
    const response = await UserBridge.login(email, password);
    const result = await response.json();
    if (result.error) {
      navi("/login");
      alert(result.msg);
      setButtonLoader(false);
    } else {
      const params = new URLSearchParams(window.location.search);
      const to = params.get('to')
      if(to === 'clients'){
        navi("/clients")
      } else {
        navi("/providers");
      }
    }
    setButtonLoader(false);
  };

  return (
    <section className="loginDefault">
      <img src="../../assets/watermark.png" alt="" />
      <div className="inputs">
        <input
          name="email"
          id="email"
          type="email"
          placeholder="Please enter your email"
          autoComplete="email"
        />
        <input
          name="password"
          id="password"
          type="password"
          placeholder="Please enter your password"
          autoComplete="password"
        />
        <button
          id="loginButton"
          onClick={async () => {
            tryLogin();
          }}
        >
          {buttonLoader ? <MicroLoader /> : "Login"}
        </button>
        <Link to="/login/signup">Sign Up</Link>
        <Link to="/login/reset">Forgot Password</Link>
      </div>
    </section>
  );
};

export default LoginDefault;
