import React, { useEffect } from "react";
import PageLoader from "../../components/PageLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import ServiceBridge from "../../bridges/ServiceBridge";
import UserBridge from "../../bridges/UserBridge";
import { debounce } from "lodash";
import MicroLoader from "../../components/MicroLoader";
import CalendarBridge from "../../bridges/CalendarBridge";

const BookingCollect = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [serviceName, setServiceName] = React.useState("");
  const [serviceDuration, setServiceDuration] = React.useState("");
  const [bookingDate, setBookingDate] = React.useState("");
  const [bookingTime, setBookingTime] = React.useState("");
  const [emailExists, setEmailExists] = React.useState(false);
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(false);

  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    const params = new URLSearchParams(window.location.search);
    const time = params.get("t");
    const hours = parseInt(time.substring(0, 2), 10);
    const minutes = parseInt(time.substring(2, 4), 10);
    const bookingDate = new Date(
      params.get("y"),
      params.get("m") - 1,
      params.get("d")
    );
    bookingDate.setHours(hours);
    bookingDate.setMinutes(minutes);

    const response = await ServiceBridge.getServiceInformation({
      _id: params.get("s"),
    });
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    } else {
      console.log(result.data.name);
      console.log(result.data.duration);
      setBookingDate(bookingDate.toLocaleDateString());
      setBookingTime(bookingDate.toLocaleTimeString());
      setServiceName(result.data.name);
      setServiceDuration(result.data.duration);
      setLoaded(true);
    }
  };

  const detectEmail = async (email) => {
    const response = await UserBridge.detectUser(email);
    const result = await response.json();
    if (result.data.exists) {
      setEmailExists(true);
    } else {
      setEmailExists(false);
    }
  };

  const createEvent = async (event) => {
    setSubmitLoader(true);
    if (document.getElementById("consent").checked === false) {
      alert("You must agree to the terms and conditions");
    } else {
      const params = new URLSearchParams(window.location.search);
      const time = params.get("t");
      const hours = parseInt(time.substring(0, 2), 10);
      const minutes = parseInt(time.substring(2, 4), 10);
      const bookingDate = new Date(
        params.get("y"),
        params.get("m") - 1,
        params.get("d")
      );
      bookingDate.setHours(hours);
      bookingDate.setMinutes(minutes);

      if (!emailExists) {
        const pass1 = document.getElementById("pass1").value;
        const pass2 = document.getElementById("pass2").value;
        // validate passwords
        if (pass1.length < 8) {
          alert("Password must be at least 8 characters long");
        } else if (
          !/[A-Z]/.test(pass1) ||
          !/[a-z]/.test(pass1) ||
          !/\d/.test(pass1)
        ) {
          alert(
            "Password must contain at least one uppercase letter, one lowercase letter, and one number"
          );
        } else if (
          !/[A-Z]/.test(pass2) ||
          !/[a-z]/.test(pass2) ||
          !/\d/.test(pass2)
        ) {
          alert(
            "Confirm Password must contain at least one uppercase letter, one lowercase letter, and one number"
          );
        } else if (pass1 !== pass2) {
          alert("Passwords do not match");
        } else {
          const response = await CalendarBridge.createEvent({
            firstName: document.getElementById("firstName").value,
            lastName: document.getElementById("lastName").value,
            email: document.getElementById("email").value,
            phone: document.getElementById("phone").value,
            serviceId: { _id: params.get("s") },
            timeDay: bookingDate.getDate(),
            timeMonth: bookingDate.getMonth() + 1,
            timeYear: bookingDate.getFullYear(),
            startTime: params.get("t"),
            duration: serviceDuration,
            password: pass2,
            newAccount: true,
          });
          const result = await response.json();
          if (result.error) {
            alert(result.msg);
          } else {
            window.location.href = result.data.redirectUrl;
          }
          setSubmitLoader(false);
        }
      } else {
        const response = await CalendarBridge.createEvent({
          firstName: document.getElementById("firstName").value,
          lastName: document.getElementById("lastName").value,
          email: document.getElementById("email").value,
          phone: document.getElementById("phone").value,
          serviceId: { _id: params.get("s") },
          timeDay: bookingDate.getDate(),
          timeMonth: bookingDate.getMonth() + 1,
          timeYear: bookingDate.getFullYear(),
          startTime: params.get("t"),
          duration: serviceDuration,
          newAccount: false,
          password: document.getElementById("pass1").value,
        });
        const result = await response.json();
        if (result.error) {
          alert(result.msg);
        } else {
          window.location.href = result.data.redirectUrl;
        }
      }
    }

    setSubmitLoader(false);
  };

  const validatePassword = () => {
    const pass1 = document.getElementById("pass1").value;
    const pass2 = document.getElementById("pass2").value;
    if (pass1 !== pass2) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  if (loaded)
    return (
      <div className="bookingCollect">
        <h1>Booking Information</h1>
        <p>Please enter your information to confirm your booking</p>
        <strong>Service: {serviceName}</strong>
        <strong>Duration: {serviceDuration} minutes</strong>
        <strong>Date: {bookingDate}</strong>
        <strong>Booking Time: {bookingTime}</strong>
        <div className="inputs">
          <label htmlFor="firstName">First Name:</label>
          <input type="text" id="firstName" name="firstName" required />
          <label htmlFor="lastName">Last Name:</label>
          <input type="text" id="lastName" name="lastName" required />
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            onChange={(e) => {
              detectEmail(e.target.value);
            }}
          />
          <label htmlFor="phone">Phone Number:</label>
          <input type="tel" id="phone" name="phone" required />
          <label htmlFor="consent">
            You must agree to our{" "}
            <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ade3be07-1f93-41a7-b925-e0fa37f7ff97">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ecb0617b-e57a-452f-812d-7a73abb763dd">
              Terms of Service
            </Link>{" "}
            to book an appointment. You also must be older then 14 years of age.
            By checking the box below, you agree to these policies.
          </label>
          <input type="checkbox" id="consent" name="consent" required />
          {emailExists ? (
            <>
              <p>Account detected. Please provide your password to continue.</p>
              <label htmlFor="pass1">Password:</label>
              <input type="password" id="pass1" name="pass1" required />
            </>
          ) : (
            <>
              <p>
                We cannot find an account for the email provided. Please supply
                a password to create your account with Flex Factory.
              </p>
              <label htmlFor="pass1">Password:</label>
              <input type="password" id="pass1" name="pass1" required />
              <label htmlFor="pass2">Confirm Password:</label>
              <input
                type="password"
                id="pass2"
                name="pass2"
                required
                onChange={validatePassword}
              />
              <div className="passwordMatch">
                {passwordMatch ? (
                  <p className="pass">Passwords match</p>
                ) : (
                  <p className="fail">Passwords do not match.</p>
                )}
              </div>
            </>
          )}
          <button onClick={createEvent}>
            {submitLoader ? <MicroLoader /> : "Submit"}
          </button>
        </div>
      </div>
    );
  else return <PageLoader />;
};

export default BookingCollect;
