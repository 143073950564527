import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CalendarBridge from "../../../bridges/CalendarBridge";
import MicroLoader from "../../../components/MicroLoader";
import PageLoader from "../../../components/PageLoader";

const ProviderScheduleDay = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [loadingEvents, setLoadingEvents] = React.useState({});
  const [calendarLoader, setCalendarLoader] = React.useState(false);

  const params = useParams();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getEvents();
    setLoaded(true);
  };

  const getEvents = async () => {
    const params = new URLSearchParams(window.location.search);
    const calendarId = params.get("c");
    if (calendarId !== "null") {
      const response = await CalendarBridge.getEventsById({ _id: calendarId });
      const result = await response.json();
      if (result.error) {
        alert(result.msg);
      } else {
        setEvents(result.data);
      }
    } else {
      const response = await CalendarBridge.getEvents();
      const result = await response.json();
      if (result.error) {
        alert(result.msg);
      } else {
        setEvents(result.data);
      }
    }
  };

  const formatTimeTo12Hour = (time) => {
    let string = time.toString();
    if (string.length === 3) string = "0" + string;
    if (string.length === 1) string = "000" + string;
    let hours = parseInt(string.slice(0, 2));
    let minutes = parseInt(string.slice(2));
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ":" + minutes.toString().padStart(2, "0") + " " + ampm;
  };

  const cancelEvent = async (_id) => {
    const previousLoadingEvents = loadingEvents;
    const loaderEvent = { ...previousLoadingEvents, [_id]: true };
    const deLoaderEvent = { ...loaderEvent, [_id]: false };
    setLoadingEvents(loaderEvent);
    const response = await CalendarBridge.cancelEvent(_id);
    const result = await response.json();
    alert(result.msg);
    await getEvents();
    setLoadingEvents(deLoaderEvent);
  };

  const renderTimes = () => {
    let payload = [];
    for (let i = 0; i < events.length; i++) {
      if (
        events[i].timeDay === parseInt(params.date.split("-")[1]) &&
        events[i].timeMonth === parseInt(params.date.split("-")[0]) &&
        events[i].timeYear === parseInt(params.date.split("-")[2])
      ) {
        console.log(events[i].startTime);
        const timeString = formatTimeTo12Hour(String(events[i].startTime));
        const id = events[i]._id;
        payload.push(
          <div className="time">
            <div className="header">{timeString}</div>
            <div className="content">
              <p>
                {events[i].title}, {events[i].firstName} {events[i].lastName}
              </p>
              <button
                onClick={() => {
                  cancelEvent(events[i]._id);
                }}
              >
                {loadingEvents?.[id] ? <MicroLoader /> : "Cancel Event"}
              </button>
            </div>
          </div>
        );
      }
    }
    return payload;
  };

  if(loaded){
    return (
      <div className="providerScheduleDay">
        <h1>Provider Schedule for: {params.date}</h1>
        <div className="breadcrumbs">
          <Link to="/providers/schedule">&lt; Back to Schedule</Link>
        </div>
        <div className="content">
          {calendarLoader ? <MicroLoader /> : renderTimes()}
        </div>
      </div>
    );  
  } else return <PageLoader />
};

export default ProviderScheduleDay;
