import React, { useEffect } from "react";
import PageLoader from "../../components/PageLoader";
import CalendarBridge from "../../bridges/CalendarBridge";

const BookingConfirmation = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [serviceName, setServiceName] = React.useState("");
  const [serviceDuration, setServiceDuration] = React.useState("");
  const [bookingDate, setBookingDate] = React.useState("");
  const [bookingTime, setBookingTime] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    const params = new URLSearchParams(window.location.search);
    const eventId = params.get("e");
    await confirmEvent(eventId);
    setLoaded(true);
  };

  const formatTimeTo12Hour = (time) => {
    let string = time.toString();
    if (string.length === 3) string = "0" + string;
    if (string.length === 1) string = "000" + string;
    let hours = parseInt(string.slice(0, 2));
    let minutes = parseInt(string.slice(2));
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ":" + minutes.toString().padStart(2, "0") + " " + ampm;
  };

  const confirmEvent = async (eventId) => {
    const response = await CalendarBridge.confirmEvent({ _id: eventId });
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    } else {
      setServiceName(result.data.title);
      setServiceDuration(`${result.data.duration} minutes`);
      setBookingDate(
        result.data.timeMonth +
          "/" +
          result.data.timeDay +
          "/" +
          result.data.timeYear
      );
      setBookingTime(formatTimeTo12Hour(result.data.startTime));
      setFirstName(result.data.firstName);
      setLastName(result.data.lastName);
      setEmail(result.data.email);
      setPhone(result.data.phone);
      console.log(result.data);
    }
  };

  if (loaded)
    return (
      <div className="bookingConfirmation">
        <h1>Booking Confirmation</h1>
        <p>
          Your booking has been confirmed. You may screenshot or save this page
          for your records.
        </p>
        <div className="bookingInformation">
          <p>
            <strong>Service: </strong> {serviceName}
          </p>
          <p>
            <strong>Duration: </strong>
            {serviceDuration} minutes
          </p>
          <p>
            <strong>Date: </strong>
            {bookingDate}
          </p>
          <p>
            <strong>Booking Time: </strong>
            {bookingTime}
          </p>
          <p>
            <strong>First Name: </strong>
            {firstName}
          </p>
          <p>
            <strong>Last Name: </strong>
            {lastName}
          </p>
          <p>
            <strong>Email: </strong>
            {email}
          </p>
          <p>
            <strong>Phone: </strong>
            {phone}
          </p>
        </div>
        <h3>Thank you for choosing Flex Factory Nutrition and Wellness!</h3>
      </div>
    );
  else return <PageLoader />;
};

export default BookingConfirmation;
