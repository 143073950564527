import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import PageLoader from "../../components/PageLoader";
import MicroLoader from "../../components/MicroLoader";
import AvailabilityBridge from "../../bridges/AvailabilityBridge";

const ProviderAvailability = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [saveAvailabilityLoader, setSaveAvailabilityLoader] =
    React.useState(false);
  const [availabilityTimes, setAvailabilityTimes] = React.useState({
    mondayStart: "09:00",
    mondayEnd: "19:00",
    tuesdayStart: "09:00",
    tuesdayEnd: "19:00",
    wednesdayStart: "09:00",
    wednesdayEnd: "19:00",
    thursdayStart: "09:00",
    thursdayEnd: "19:00",
    fridayStart: "09:00",
    fridayEnd: "19:00",
    saturdayStart: "10:00",
    saturdayEnd: "17:00",
    sundayStart: "10:00",
    sundayEnd: "17:00",
  });
  const [availabilityLoader, setAvailabilityLoader] = React.useState(false);
  const [blackoutDatesLoader, setBlackoutDatesLoader] = React.useState(false);
  const [blackoutDates, setBlackoutDates] = React.useState([]);
  const [assignBlackoutLoader, setAssignBlackoutLoader] = React.useState(false);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await Promise.all([getAvailability(), getBlackoutDates()]);
    setLoaded(true);
  };

  const getBlackoutDates = async () => {
    setBlackoutDatesLoader(true);
    const response = await AvailabilityBridge.getBlackoutDates();
    const result = await response.json();
    setBlackoutDates(result.data);
    setBlackoutDatesLoader(false);
  };

  const formatTime = (time) => {
    if(time === null) return "0000"
    let string = time.toString();
    if (string.length === 3) string = "0" + string;
    return string.slice(0, 2) + ":" + string.slice(2);
  };

  const formatTimeTo12Hour = (time) => {
    if(time === null) return "0000"
    let string = time.toString();
    if (string.length === 3) string = "0" + string;
    if (string.length === 1) string = "000" + string;
    let hours = parseInt(string.slice(0, 2));
    let minutes = parseInt(string.slice(2));
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ":" + minutes.toString().padStart(2, "0") + " " + ampm;
  };

  const getAvailability = async () => {
    setAvailabilityLoader(true);
    const response = await AvailabilityBridge.getAvailability();
    const result = await response.json();
    const { data } = result;
    // add a colon in the middle of each time in data
    //  to match the html input format
    const formatted = {
      mondayStart: formatTime(data.mondayStart),
      mondayEnd: formatTime(data.mondayEnd),
      tuesdayStart: formatTime(data.tuesdayStart),
      tuesdayEnd: formatTime(data.tuesdayEnd),
      wednesdayStart: formatTime(data.wednesdayStart),
      wednesdayEnd: formatTime(data.wednesdayEnd),
      thursdayStart: formatTime(data.thursdayStart),
      thursdayEnd: formatTime(data.thursdayEnd),
      fridayStart: formatTime(data.fridayStart),
      fridayEnd: formatTime(data.fridayEnd),
      saturdayStart: formatTime(data.saturdayStart),
      saturdayEnd: formatTime(data.saturdayEnd),
      sundayStart: formatTime(data.sundayStart),
      sundayEnd: formatTime(data.sundayEnd),
    };
    setAvailabilityTimes(formatted);
    setAvailabilityLoader(false);
  };

  const saveAvailability = async () => {
    setSaveAvailabilityLoader(true);
    const response = await AvailabilityBridge.update({
      mondayStart: document.getElementById("mondayStart").value,
      mondayEnd: document.getElementById("mondayEnd").value,
      tuesdayStart: document.getElementById("tuesdayStart").value,
      tuesdayEnd: document.getElementById("tuesdayEnd").value,
      wednesdayStart: document.getElementById("wednesdayStart").value,
      wednesdayEnd: document.getElementById("wednesdayEnd").value,
      thursdayStart: document.getElementById("thursdayStart").value,
      thursdayEnd: document.getElementById("thursdayEnd").value,
      fridayStart: document.getElementById("fridayStart").value,
      fridayEnd: document.getElementById("fridayEnd").value,
      saturdayStart: document.getElementById("saturdayStart").value,
      saturdayEnd: document.getElementById("saturdayEnd").value,
      sundayStart: document.getElementById("sundayStart").value,
      sundayEnd: document.getElementById("sundayEnd").value,
    });
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    }
    await getAvailability();
    setSaveAvailabilityLoader(false);
  };

  const removeBlackout = async (_id) => {
    const button = document.getElementById(`removeBlackout${_id}`);
    const root = createRoot(button).render(<MicroLoader />);
    const response = await AvailabilityBridge.removeblackout({ _id });
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    }
    await getBlackoutDates();
  };

  const assignBlackout = async () => {
    setAssignBlackoutLoader(true);
    const dateInput = document.getElementById("blackoutDate");
    const dateValue = dateInput.value;
    const [year, month, day] = dateValue.split("-");
    const dateObject = new Date(year, month - 1, day);
    const blackoutStartTime =
      document.getElementById("blackoutStartTime").value;
    const blackoutEndTime = document.getElementById("blackoutEndTime").value;
    const formattedStartTime = blackoutStartTime.replace(":", "");
    const formattedEndTime = blackoutEndTime.replace(":", "");
    const data = {
      timeYear: dateObject.getFullYear(),
      timeMonth: dateObject.getMonth() + 1,
      timeDay: dateObject.getDate(),
      startTime: formattedStartTime,
      endTime: formattedEndTime,
    };
    const response = await AvailabilityBridge.assignblackout(data);
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    }
    await getBlackoutDates();
    setAssignBlackoutLoader(false);
  };

  const renderBlackoutDates = () => {
    let payload = [];
    for (let i = 0; i < blackoutDates.length; i++) {
      const date = new Date();
      date.setFullYear(
        blackoutDates[i].timeYear,
        blackoutDates[i].timeMonth - 1,
        blackoutDates[i].timeDay
      );
      const dateString = date.toLocaleDateString();
      const startTimeString = formatTimeTo12Hour(blackoutDates[i].startTime);
      const endTimeString = formatTimeTo12Hour(blackoutDates[i].endTime);
      payload.push(
        <div className="day">
          <div className="date">{dateString}</div>
          <div className="times">
            <strong>Start Time:</strong> {startTimeString}
            <strong>End Time:</strong> {endTimeString}
            <button
              id={`removeBlackout${blackoutDates[i]._id}`}
              onClick={() => {
                removeBlackout(blackoutDates[i]._id);
              }}
            >
              Remove Date
            </button>
          </div>
        </div>
      );
    }
    return payload;
  };

  if (loaded)
    return (
      <div className="providerAvailability">
        <h1>Provider Availability</h1>
        <p>
          Here you may set your availability and manage blackout dates for your
          schedule.
        </p>
        <p>
          Blackout dates overide your typical availability, and prevent booking
          during the time selected. Use this if you take vacation or time off.
        </p>
        <div className="availability">
          {availabilityLoader ? (
            <MicroLoader />
          ) : (
            <>
              <div className="day">
                <div className="left">
                  <strong>Monday</strong>
                </div>
                <div className="right">
                  <label htmlFor="mondayStart">Start Time:</label>
                  <input
                    type="time"
                    id="mondayStart"
                    name="mondayStart"
                    defaultValue={availabilityTimes.mondayStart}
                  />
                  <label htmlFor="mondayEnd">End Time:</label>
                  <input
                    type="time"
                    id="mondayEnd"
                    name="mondayEnd"
                    defaultValue={availabilityTimes.mondayEnd}
                  />
                </div>
              </div>
              <div className="day">
                <div className="left">
                  <strong>Tuesday</strong>
                </div>
                <div className="right">
                  <label htmlFor="tuesdayStart">Start Time:</label>
                  <input
                    type="time"
                    id="tuesdayStart"
                    name="tuesdayStart"
                    defaultValue={availabilityTimes.tuesdayStart}
                  />
                  <label htmlFor="tuesdayEnd">End Time:</label>
                  <input
                    type="time"
                    id="tuesdayEnd"
                    name="tuesdayEnd"
                    defaultValue={availabilityTimes.tuesdayEnd}
                  />
                </div>
              </div>
              <div className="day">
                <div className="left">
                  <strong>Wednesday</strong>
                </div>
                <div className="right">
                  <label htmlFor="wednesdayStart">Start Time:</label>
                  <input
                    type="time"
                    id="wednesdayStart"
                    name="wednesdayStart"
                    defaultValue={availabilityTimes.wednesdayStart}
                  />
                  <label htmlFor="wednesdayEnd">End Time:</label>
                  <input
                    type="time"
                    id="wednesdayEnd"
                    name="wednesdayEnd"
                    defaultValue={availabilityTimes.wednesdayEnd}
                  />
                </div>
              </div>
              <div className="day">
                <div className="left">
                  <strong>Thursday</strong>
                </div>
                <div className="right">
                  <label htmlFor="thursdayStart">Start Time:</label>
                  <input
                    type="time"
                    id="thursdayStart"
                    name="thursdayStart"
                    defaultValue={availabilityTimes.thursdayStart}
                  />
                  <label htmlFor="thursdayEnd">End Time:</label>
                  <input
                    type="time"
                    id="thursdayEnd"
                    name="thursdayEnd"
                    defaultValue={availabilityTimes.thursdayEnd}
                  />
                </div>
              </div>
              <div className="day">
                <div className="left">
                  <strong>Friday</strong>
                </div>
                <div className="right">
                  <label htmlFor="fridayStart">Start Time:</label>
                  <input
                    type="time"
                    id="fridayStart"
                    name="fridayStart"
                    defaultValue={availabilityTimes.fridayStart}
                  />
                  <label htmlFor="fridayEnd">End Time:</label>
                  <input
                    type="time"
                    id="fridayEnd"
                    name="fridayEnd"
                    defaultValue={availabilityTimes.fridayEnd}
                  />
                </div>
              </div>
              <div className="day">
                <div className="left">
                  <strong>Saturday</strong>
                </div>
                <div className="right">
                  <label htmlFor="saturdayStart">Start Time:</label>
                  <input
                    type="time"
                    id="saturdayStart"
                    name="saturdayStart"
                    defaultValue={availabilityTimes.saturdayStart}
                  />
                  <label htmlFor="saturdayEnd">End Time:</label>
                  <input
                    type="time"
                    id="saturdayEnd"
                    name="saturdayEnd"
                    defaultValue={availabilityTimes.saturdayEnd}
                  />
                </div>
              </div>
              <div className="day">
                <div className="left">
                  <strong>Sunday</strong>
                </div>
                <div className="right">
                  <label htmlFor="sundayStart">Start Time:</label>
                  <input
                    type="time"
                    id="sundayStart"
                    name="sundayStart"
                    defaultValue={availabilityTimes.sundayStart}
                  />
                  <label htmlFor="sundayEnd">End Time:</label>
                  <input
                    type="time"
                    id="sundayEnd"
                    name="sundayEnd"
                    defaultValue={availabilityTimes.sundayEnd}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="inputs">
          <label htmlFor="saveAvailability">Save Availability</label>
          <button name="saveAvailability" onClick={saveAvailability}>
            {saveAvailabilityLoader ? <MicroLoader /> : "Save Availability"}
          </button>
        </div>
        <div className="blackoutDates">
          <h2>Blackout Dates</h2>
          <div className="text">
            <p>
              To take off an entire day, simply leave the times and click
              assign. If you need to take off a partial day, remember how long
              your services are. If you need to leave at 2pm, for example, and
              your longest service is 60 minutes, you should black out 1pm so
              that your latest appointment does not run into your blackout time.
            </p>
          </div>

          <div className="controls">
            <label htmlFor="date">Date</label>
            <input type="date" id="blackoutDate" name="date" />
            <label htmlFor="startTime">Start Time:</label>
            <input
              type="time"
              id="blackoutStartTime"
              name="startTime"
              defaultValue="00:00"
            />
            <label htmlFor="endDate">End Time:</label>
            <input
              type="time"
              id="blackoutEndTime"
              name="endTime"
              defaultValue="23:59"
            />
            <button onClick={assignBlackout}>
              {assignBlackoutLoader ? <MicroLoader /> : "Assign Date"}
            </button>
          </div>
          {blackoutDatesLoader ? <MicroLoader /> : renderBlackoutDates()}
        </div>
      </div>
    );
  else return <PageLoader />;
};

export default ProviderAvailability;
