import React from "react";
import { Outlet } from "react-router";
import BookingHeader from "../../components/BookingHeader";
import BookingFooter from "../../components/BookingFooter";

const BookingView = () => {
  return (
    <section className="bookingView">
      <BookingHeader />
      <div className="content">
        <Outlet />
      </div>
      <BookingFooter />
    </section>
  );
};

export default BookingView;
