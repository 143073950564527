import React from 'react';

const ErrorView = ({code, message}) => {
    const goBack = () => {
        window.history.back()
    }
    return (
        <section className="errorView">
            <h1>Error</h1>
            <h2>Status Code: {code}</h2>
            <p>{message}</p>
            <button onClick={goBack}>Go Back</button>
        </section>
    );
}

export default ErrorView;
