import React from "react";
import { Outlet } from "react-router";

const LoginView = () => {

  return (
    <section className="loginView">
      <Outlet />
    </section>
  );
};

export default LoginView;
