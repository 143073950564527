import React, { useEffect, useState } from "react";
import PageLoader from "../../../components/PageLoader";
import { useNavigate } from "react-router";
import CalendarBridge from "../../../bridges/CalendarBridge";
import UserBridge from "../../../bridges/UserBridge";
import MicroLoader from "../../../components/MicroLoader";

const ProviderSchedule = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("month");
  const [calendarMonth, setCalendarMonth] = React.useState(
    new Date().getMonth() + 1
  );
  const [calendarYear, setCalendarYear] = React.useState(
    new Date().getFullYear()
  );
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [calendarLoader, setCalendarLoader] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState('null');

  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    const [...results] = await Promise.all([
      getEvents(),
      getUser(),
      getCalendars(),
    ]);
    setLoaded(true);
  };

  const getEvents = async (_id) => {
    if (_id !== undefined) {
      const response = await CalendarBridge.getEventsById({ _id: _id });
      const result = await response.json();
      if (result.error) {
        alert(result.msg);
      } else {
        setEvents(result.data);
      }
    } else {
      const response = await CalendarBridge.getEvents();
      const result = await response.json();
      if (result.error) {
        alert(result.msg);
      } else {
        setEvents(result.data);
      }
    }
  };

  const getCalendars = async () => {
    const response = await CalendarBridge.getCalendars();
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    } else {
      setCalendars(result.data);
    }
  };

  const getUser = async () => {
    const response = await UserBridge.getCurrent();
    const result = await response.json();
    if (result.error) {
      alert(result.msg);
    } else {
      setUser(result.data);
    }
  };

  const prevMonth = () => {
    // reduce the current month by 1
    if (calendarMonth === 1) {
      setCalendarMonth(12);
      setCalendarYear(calendarYear - 1);
    } else {
      setCalendarMonth(calendarMonth - 1);
    }
  };

  const nextMonth = () => {
    // increase the current month by 1
    if (calendarMonth === 12) {
      setCalendarMonth(1);
      setCalendarYear(calendarYear + 1);
    } else {
      setCalendarMonth(calendarMonth + 1);
    }
  };

  const renderCurrentMonth = () => {
    let monthName;
    switch (calendarMonth) {
      case 1:
        monthName = "January";
        break;
      case 2:
        monthName = "February";
        break;
      case 3:
        monthName = "March";
        break;
      case 4:
        monthName = "April";
        break;
      case 5:
        monthName = "May";
        break;
      case 6:
        monthName = "June";
        break;
      case 7:
        monthName = "July";
        break;
      case 8:
        monthName = "August";
        break;
      case 9:
        monthName = "September";
        break;
      case 10:
        monthName = "October";
        break;
      case 11:
        monthName = "November";
        break;
      case 12:
        monthName = "December";
        break;
    }
    return monthName + " " + calendarYear;
  };

  const formatTimeTo12Hour = (time) => {
    let string = time.toString();
    if (string.length === 3) string = "0" + string;
    if (string.length === 1) string = "000" + string;
    let hours = parseInt(string.slice(0, 2));
    let minutes = parseInt(string.slice(2));
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ":" + minutes.toString().padStart(2, "0") + " " + ampm;
  };

  const renderDaysOfMonth = () => {
    // Add div for each day of month starting from Sunday
    const firstDayOfMonth = new Date(
      calendarYear,
      calendarMonth - 1,
      1
    ).getDay();
    const daysInMonth = new Date(calendarYear, calendarMonth, 0).getDate();
    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div className="dayBlank"></div>);
    }

    const assignClass = (day) => {
      const todaysDate = new Date();
      const currentDay = todaysDate.getDate();
      console.log(calendarYear);
      console.log(todaysDate.getFullYear())
      if (
        calendarYear === todaysDate.getFullYear() &&
        calendarMonth === todaysDate.getMonth() + 1
      ) {
        if (day < currentDay) {
          return "dayPast";
        } else if (day === currentDay) {
          return "dayCurrent";
        } else {
          return "day";
        }
      } else if(calendarMonth > todaysDate.getMonth() && calendarYear === todaysDate.getFullYear() || calendarYear > todaysDate.getFullYear()) {
        return "day";
      } else {
        return "dayPast";
      }
    };

    for (let i = 1; i <= daysInMonth; i++) {
      const navLink = `/providers/schedule/${calendarMonth}-${i}-${calendarYear}?c=${selectedCalendar}`;
      let numberOfEventsToday = 0;
      let payload = [];
      events.forEach((event) => {
        if (
          event.timeDay === i &&
          event.timeMonth === calendarMonth &&
          event.timeYear === calendarYear
        ) {
          numberOfEventsToday++;
          const formattedTime = formatTimeTo12Hour(event.startTime);
          payload.push(
            <div className="event">
              <div className="title">{event.title}</div>
              <div className="time">{formattedTime}</div>
              <div className="duration">{event.duration} Minutes</div>
              <div className="attendees">
                {event.firstName} {event.lastName}
              </div>
            </div>
          );
        }
      });
      days.push(
        <div
          className={assignClass(i)}
          onClick={() => {
            nav(navLink);
          }}
        >
          <div className="number">
            <strong>{i}</strong>
          </div>
          <div className="events">
            <p>Events Today: {numberOfEventsToday}</p>
            {payload}
          </div>
        </div>
      );
    }

    return days;
  };

  const switchCalendar = async (calendarId) => {
    setCalendarLoader(true);
    await getEvents(calendarId);
    setSelectedCalendar(calendarId);
    setCalendarLoader(false);
  };

  if (loaded)
    return (
      <div className="providerSchedule">
        <h1>Provider Schedule</h1>
        <p>
          Welcome to your calendar. Here you may see your scheduled events.
          Click on a day to see more detail.
        </p>
        {user && parseInt(user.authLevel) >= 2 ? (
          <div className="inputs">
            <label htmlFor="calendar">Select a Calendar to View</label>
            <select
              id="calendar"
              name="calendar"
              onChange={(e) => {
                switchCalendar(e.target.value);
              }}
            >
              <option value={null}>Select a Calendar</option>
              {calendars.map((calendar) => {
                return (
                  <option key={calendar._id} value={calendar._id}>
                    {calendar.assignedFirstName} {calendar.assignedLastName}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}
        {calendarLoader ? (
          <MicroLoader />
        ) : (
          <div className="calendar">
            <div className="calendarMonth">
              <button onClick={prevMonth}>&lt;</button>
              <div className="currentMonth">{renderCurrentMonth()}</div>
              <button onClick={nextMonth}>&gt;</button>
            </div>
            <div className="dayPicker">
              <div className="daysOfWeek">
                <div>Sun</div>
                <div>Mon</div>
                <div>Tue</div>
                <div>Wed</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
              </div>
              <div className="days">{renderDaysOfMonth()}</div>
            </div>
          </div>
        )}
      </div>
    );
  else return <PageLoader />;
};

export default ProviderSchedule;
