import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import BookingFooter from "../../components/BookingFooter";
import ProviderHeader from "../../components/ProviderHeader";
import UserBridge from "../../bridges/UserBridge";
import PageLoader from "../../components/PageLoader";

const ProviderView = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getUser();
    setLoaded(true);
  };

  const getUser = async () => {
    const response = await UserBridge.readCurrentUser();
    const result = await response.json();
    if (result.data.authLevel < 1) {
      nav("/login");
    }
  };
  if (loaded) {
    return (
      <section className="providerView">
        <ProviderHeader />
        <div className="content">
          <Outlet />
        </div>
        <BookingFooter />
      </section>
    );
  } else return <PageLoader />
};

export default ProviderView;
