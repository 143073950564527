import React, { useEffect } from "react";
import PageLoader from "../../components/PageLoader";
import ServiceBridge from "../../bridges/ServiceBridge";
import MicroLoader from "../../components/MicroLoader";
import AvailabilityBridge from "../../bridges/AvailabilityBridge";
import { useNavigate } from "react-router";

const BookingHome = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [calendarMonth, setCalendarMonth] = React.useState(
    new Date().getMonth() + 1
  );
  const [calendarYear, setCalendarYear] = React.useState(
    new Date().getFullYear()
  );
  const [servicesList, setServicesList] = React.useState([]);
  const [selectedService, setSelectedService] = React.useState(null);
  const [availabilityLoader, setAvailabilityLoader] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(null);
  const [availableTimes, setAvailableTimes] = React.useState([]);

  const nav = useNavigate();

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    await getServiceList();
    setLoaded(true);
  };

  const getServiceList = async () => {
    try {
      const response = await ServiceBridge.getServiceList();
      const result = await response.json();
      if (result.error) {
        console.error("Error retrieving service list:", result.msg);
        return false;
      } else {
        // update the service list state with the retrieved data
        console.log(result.data);
        setServicesList(result.data);
        return true;
      }
    } catch (error) {
      console.error("Error retrieving service list:", error);
      return false;
    }
  };

  const prevMonth = () => {
    // reduce the current month by 1
    if (calendarMonth === 1) {
      setCalendarMonth(12);
      setCalendarYear(calendarYear - 1);
    } else {
      setCalendarMonth(calendarMonth - 1);
    }
  };

  const pickService = async (serviceId, day) => {
    setAvailabilityLoader(true);
    setSelectedService(serviceId);
    if (!selectedDay && !day) {
      alert("Please select a day first");
    } else {
      const response = await AvailabilityBridge.getAvailableTimes({
        timeDay: day ? day : selectedDay,
        timeMonth: calendarMonth,
        timeYear: calendarYear,
        serviceID: serviceId,
      });
      const result = await response.json();
      if (result.error) {
        alert(result.msg);
      } else {
        setAvailableTimes(result.data);
      }
    }
    setAvailabilityLoader(false);
  };

  const nextMonth = () => {
    // increase the current month by 1
    if (calendarMonth === 12) {
      setCalendarMonth(1);
      setCalendarYear(calendarYear + 1);
    } else {
      setCalendarMonth(calendarMonth + 1);
    }
  };

  const formatTimeTo12Hour = (time) => {
    let string = time.toString();
    if (string.length === 3) string = "0" + string;
    if (string.length === 1) string = "000" + string;
    let hours = parseInt(string.slice(0, 2));
    let minutes = parseInt(string.slice(2));
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ":" + minutes.toString().padStart(2, "0") + " " + ampm;
  };

  const renderCurrentMonth = () => {
    let monthName;
    switch (calendarMonth) {
      case 1:
        monthName = "January";
        break;
      case 2:
        monthName = "February";
        break;
      case 3:
        monthName = "March";
        break;
      case 4:
        monthName = "April";
        break;
      case 5:
        monthName = "May";
        break;
      case 6:
        monthName = "June";
        break;
      case 7:
        monthName = "July";
        break;
      case 8:
        monthName = "August";
        break;
      case 9:
        monthName = "September";
        break;
      case 10:
        monthName = "October";
        break;
      case 11:
        monthName = "November";
        break;
      case 12:
        monthName = "December";
        break;
    }
    return monthName + " " + calendarYear;
  };

  const renderDaysOfMonth = () => {
    // Add div for each day of month starting from Sunday
    const firstDayOfMonth = new Date(
      calendarYear,
      calendarMonth - 1,
      1
    ).getDay();
    const daysInMonth = new Date(calendarYear, calendarMonth, 0).getDate();
    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div className="dayBlank"></div>);
    }

    const assignClass = (day) => {
      const todaysDate = new Date();
      const currentDay = todaysDate.getDate();
      console.log(calendarYear);
      console.log(todaysDate.getFullYear())
      if (
        calendarYear === todaysDate.getFullYear() &&
        calendarMonth === todaysDate.getMonth() + 1
      ) {
        if (day < currentDay) {
          return "dayPast";
        } else if (day === currentDay) {
          return "dayCurrent";
        } else {
          return "day";
        }
      } else if(calendarMonth > todaysDate.getMonth() && calendarYear === todaysDate.getFullYear() || calendarYear > todaysDate.getFullYear()) {
        return "day";
      } else {
        return "dayPast";
      }
    };

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(
        <div
          id={`day-${i}`}
          name="calendarday"
          className={assignClass(i)}
          onClick={() => {
            pickDay(i);
          }}
        >
          <div>{i}</div>
        </div>
      );
    }

    return days;
  };

  const renderTimes = () => {
    return availableTimes.map((time) => (
      <div
        className="time"
        onClick={() => {
          nav(
            `/collect?y=${calendarYear}&m=${calendarMonth}&d=${selectedDay}&t=${time}&s=${selectedService}`
          );
        }}
      >
        {formatTimeTo12Hour(time)}
      </div>
    ));
  };

  const pickDay = async (day) => {
    // clear .dayPicked classes from all divs with name "calendarday"

    const dayElements = document.getElementsByName("calendarday");
    dayElements.forEach((element) => {
      element.classList.remove("dayPicked");
    });

    // add.dayPicked class to the clicked day
    const dayElement = document.getElementById(`day-${day}`);
    dayElement.classList.add("dayPicked");
    if (selectedService) {
      setSelectedDay(day);
      await pickService(selectedService, day);
    } else {
      setSelectedDay(day);
    }
  };

  const renderSelectedDay = () => {
    const date = new Date(calendarYear, calendarMonth - 1, selectedDay);
    return date.toLocaleDateString();
  };

  if (loaded)
    return (
      <div className="bookingHome">
        <div className="content">
          <div className="left">
            <div className="miniCalendar">
              <div className="header">
                <div className="prevMonth">
                  <button onClick={prevMonth}>&lt;</button>
                </div>
                <div className="currentMonth">{renderCurrentMonth()}</div>
                <div className="nextMonth">
                  <button onClick={nextMonth}>&gt;</button>
                </div>
              </div>
              <div className="dayPicker">
                <div className="daysOfWeek">
                  <div>Sun</div>
                  <div>Mon</div>
                  <div>Tue</div>
                  <div>Wed</div>
                  <div>Thu</div>
                  <div>Fri</div>
                  <div>Sat</div>
                </div>
                <div className="days">{renderDaysOfMonth()}</div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="header">
              <h3>{selectedDay ? renderSelectedDay() : "Please Pick a Day"}</h3>
            </div>
            <div className="inputs">
              <label htmlFor="service">Select a Service</label>
              <select
                id="service"
                name="service"
                onChange={(e) => {
                  pickService(e.target.value);
                }}
              >
                <option value={null}>Select a Service</option>
                {servicesList.map((service) => {
                  return <option value={service._id}>{service.name}</option>;
                })}
              </select>
            </div>
            <div className="availabilityView">
              <h3>Available Times:</h3>
              <div className="times">
                {availabilityLoader ? (
                  <MicroLoader />
                ) : selectedService ? (
                  <>{renderTimes()}</>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <PageLoader />;
};

export default BookingHome;
