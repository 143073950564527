import React from "react";
import { Link } from "react-router-dom";

const BookingFooter = () => {
  return (
    <div className="bookingFooter">
      <div className="logos">
        <img
          src="/assets/watermark.png"
          alt="Sierra Precision Home Inspection Logo"
        />
        <p>|</p>
        <img
          src="/assets/dcwm.png"
          alt="Denicode LLC Software House watermark"
        />
      </div>
      <div className="legal">
        <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ecb0617b-e57a-452f-812d-7a73abb763dd">Terms of Use</Link>
        <Link to="https://app.termly.io/policy-viewer/policy.html?policyUUID=ade3be07-1f93-41a7-b925-e0fa37f7ff97">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default BookingFooter;
