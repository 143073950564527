export default class UserBridge {
  static async createUser(authLevel, organization, fname, lname, email, phone) {
    try {
      let response = await fetch("/api/users/create", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authLevel: authLevel,
          organization: organization,
          fname: fname,
          lname: lname,
          email: email,
          phone: phone,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async readCurrentUser() {
    try {
      let response = await fetch("/api/users/readcurrent", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async detectUser(email) {
    try {
      let response = await fetch("/api/users/detectuser", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async readUser(_id) {
    try {
      let response = await fetch("/api/users/read", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getuser(_id) {
    try {
      let response = await fetch("/api/users/read", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getCurrent() {
    try {
      let response = await fetch("/api/users/readcurrent", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async readUserList() {
    try {
      let response = await fetch("/api/users/readlist", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateUser(_id, updates) {
    try {
      let response = await fetch("/api/users/update", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
          updates: updates,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async deleteUser(_id) {
    try {
      let response = await fetch("/api/users/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async forceResetPassword(_id) {
    try {
      let response = await fetch("/api/users/forcereset", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async signUp(fname, lname, email, password) {
    try {
      let response = await fetch("/api/users/signup", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          fname: fname,
          lname: lname,
          email: email,
          password: password,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async login(email, password) {
    try {
      let response = await fetch("/api/users/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async logout() {
    try {
      let response = await fetch("/api/users/logout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async clockIn(posPin) {
    try {
      let response = await fetch("/api/users/clockin", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          posPin: posPin,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async clockOut(posPin) {
    try {
      let response = await fetch("/api/users/clockout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          posPin: posPin,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async breakIn(posPin) {
    try {
      let response = await fetch("/api/users/breakin", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          posPin: posPin,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async breakOut(posPin) {
    try {
      let response = await fetch("/api/users/breakout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          posPin: posPin,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async readCurrentUsers() {
    try {
      let response = await fetch("/api/users/readcurrents", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async readServiceProviders() {
    try {
      let response = await fetch("/api/users/readserviceproviders", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async readUserServices(_id) {
    try {
      let response = await fetch("/api/users/readservices", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async addServiceTickets(_id, serviceId, quantity) {
    try {
      let response = await fetch("/api/users/addtickets", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
          serviceId: serviceId,
          quantity: quantity,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async removeServiceTickets(_id, serviceId, quantity) {
    try {
      let response = await fetch("/api/users/removetickets", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: _id,
          serviceId: serviceId,
          quantity: quantity,
        }),
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
