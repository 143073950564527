import React, { useEffect } from "react";
import { Route, Routes, useParams } from "react-router";
import ErrorView from "./views/error/ErrorView";
import LoginDefault from "./views/login/LoginDefault";
import LoginReset from "./views/login/LoginReset";
import LoginSignup from "./views/login/LoginSignup";
import LoginView from "./views/login/LoginView";
import BookingHome from "./views/booking/BookingHome";
import BookingView from "./views/booking/BookingView";
import ProviderView from "./views/provider/ProviderView";
import ProviderHome from "./views/provider/ProviderHome";
import ProviderSchedule from "./views/provider/ProviderSchedule/ProviderSchedule";
import ProviderAvailability from "./views/provider/ProviderAvailability";
import ProviderScheduleDay from "./views/provider/ProviderSchedule/ProviderScheduleDay";
import BookingCollect from "./views/booking/BookingCollect";
import BookingConfirmation from "./views/booking/BookingConfirmation";
import BookingPos from "./views/booking/BookingPos";
import ClientView from "./views/client/ClientView";
import ClientHome from "./views/client/ClientHome";
import ClientScheduleDay from "./views/client/ClientSchedule/ClientScheduleDay";
import ClientSchedule from "./views/client/ClientSchedule/ClientSchedule";
const App = () => {
  const params = useParams();
  useEffect(() => {
    console.log(`---->!<----`);
  }, []);

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Booking - FFNW</title>
        <meta
          name="description"
          content="View available services and book appointments with FFNW Booking."
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="/assets/favicon-square-alpha.png"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="/main.css" />
      </head>
      <body>
        <main>
          <Routes>
            <Route path="/" element={<BookingView />}>
              <Route index element={<BookingHome />} />
              <Route path="collect" element={<BookingCollect />} />
              <Route path="confirmation" element={<BookingConfirmation />} />
            </Route>
            <Route path="/providers" element={<ProviderView />}>
              <Route index element={<ProviderHome />} />
              <Route path="availability" element={<ProviderAvailability />} />
              <Route path="schedule">
                <Route index element={<ProviderSchedule />} />
                <Route path=":date" element={<ProviderScheduleDay />} />
              </Route>
              <Route path="pos" element={<BookingPos />} />
            </Route>
            <Route path="/clients" element={<ClientView />}>
              <Route index element={<ClientHome />} />
              <Route path="schedule">
                <Route index element={<ClientSchedule />} />
                <Route path=":date" element={<ClientScheduleDay />} />
              </Route>
              <Route path="pos" element={<BookingPos />} />
            </Route>
            <Route path="/login" element={<LoginView />}>
              <Route index element={<LoginDefault />} />
              <Route path="reset/:id" element={<LoginReset />} />
              <Route path="signup" element={<LoginSignup />} />
            </Route>
            <Route
              path="*"
              element={
                <ErrorView
                  code={404}
                  message="The page you are looking for does not exist."
                />
              }
            />
          </Routes>
        </main>
      </body>
    </html>
  );
};

export default App;
