import React, { useEffect } from "react";
import PageLoader from "../../components/PageLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserBridge from "../../bridges/UserBridge";

const ProviderHome = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [authLevel, setAuthLevel] = React.useState(null);
  const nav = useNavigate();
  const location = useLocation();

  useEffect(() => {
    pageLoad();
  }, [location]);

  const pageLoad = async () => {
    await detectUser();
    console.log("Provider Home Page Loaded");
    setLoaded(true);
  };

  const detectUser = async () => {
    const response = await UserBridge.readCurrentUser();
    const result = await response.json();
    if (result.data === null || result.error) {
      nav("/login", { replace: true });
    } else {
      setAuthLevel(result.data.authLevel);
    }
  };

  if (loaded)
    return (
      <div className="providerHome">
        <h1>FFNW Booking - Providers</h1>
        <p>Here you may manage your availability and see your schedule.</p>
        <div className="cardRows">
          <div
            className="card"
            onClick={() => {
              nav("/providers/availability");
            }}
          >
            <Link to="/providers/availability">Availability</Link>
          </div>
          <div
            className="card"
            onClick={() => {
              nav("/providers/schedule");
            }}
          >
            <Link to="/providers/schedule">Schedule</Link>
          </div>
          <div
            className="card"
            onClick={() => {
              nav("/");
            }}
          >
            <Link to="/">Booking View</Link>
          </div>
          {authLevel >= 2 ? (
            <div
              className="card"
              onClick={() => {
                nav("/providers/pos");
              }}
            >
              <Link to="/pos">Point of Sale Booking</Link>
            </div>
          ) : null}
        </div>
      </div>
    );
  else return <PageLoader />;
};

export default ProviderHome;
